var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.contextMenu,style:({ left: _vm.mouseX + 'px', top: _vm.mouseY + 'px' })},_vm._l((_vm.pages),function(page){return _c('router-link',{key:page.name,class:_vm.$style.link,attrs:{"to":{
      name: page.name,
      query: {
        dateFrom: _vm.dateFrom,
        dateTo: _vm.dateTo,
        isWorktimeOnly: _vm.isWorktimeOnly,
        state: 2,
        selectedComputerId: _vm.userId,
      },
    },"target":"_blank"}},[_vm._v(" "+_vm._s(page.text)+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }