<template>
  <div
    :class="$style.contextMenu"
    :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
  >
    <router-link
      v-for="page in pages"
      :key="page.name"
      :class="$style.link"
      :to="{
        name: page.name,
        query: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          isWorktimeOnly: isWorktimeOnly,
          state: 2,
          selectedComputerId: userId,
        },
      }"
      target="_blank"
    >
      {{ page.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: [
    'userId',
    'mouseX',
    'mouseY',
    'dateFrom',
    'dateTo',
    'isWorktimeOnly',
    'pages',
  ],
};
</script>

<style lang="scss" module>
.contextMenu {
  box-shadow: 4px 6px 14px rgba(165, 165, 165, 0.4);
  position: absolute;
  background-color: white;
  padding: 10px;
}

.link {
  text-decoration: none;
  display: block;
  padding: 3px;
  color: #4b4b4b;
  font-size: 14px;
  &:hover {
    background: $borderColor;
  }
}
</style>
